<template>
  <div
    class="w-full flex sm:w-1/2 lg:w-1/4 px-4 mb-4"
    @click="upgradeToElite"
  >
    <vx-card class="rj-invoice-card">
      <div class="flex items-center justify-between mb-4">
        <div>
          <h2>{{ formatCentsAsDollars(commission.totalBonusAmountWithoutProrations) }}</h2>
          <p>Elite Partner Bonus</p>
        </div>
        <feather-icon
          icon="AwardIcon"
          class="p-3 inline-flex rounded-full bg-opacity-25"
          :style="{
            color: themeColors.rjGreen,
            'background-color': Color(themeColors.rjGreen)
              .lightness(93)
              .hex(),
          }"
        />
      </div>

      <div class="mt-auto">
        <vs-button
          v-if="isCertifiedPartner"
          color="primary"
          class="w-full"
        >
          Become Elite
        </vs-button>

        <p v-if="eliteBonusEligible">
          Congrats! You’re earning the 10% bonus!
        </p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import { bus, formatCentsAsDollars } from '@/utils';
import { CERTIFIED_PARTNER_PLAN } from '@/rj_consts';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjElitePartnerBonus',
  mixins: [Authorizable],
  data() {
    return {
      themeColors,
      Color,
    };
  },
  computed: {
    commission() {
      return this.$store.getters['partners/commission'];
    },
    eliteBonusEligible() {
      return this.$store.getters['partners/eliteBonusEligible'];
    },
    isCertifiedPartner() {
      return this.guard_partner_plan(CERTIFIED_PARTNER_PLAN);
    },
  },
  methods: {
    formatCentsAsDollars,
    upgradeToElite() {
      if (this.isCertifiedPartner) {
        bus.$emit('show-modal', { modal: 'become-elite' });
      }
    },
  },
};
</script>
