<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Upload your client’s logo.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        Your client's customers may see this logo in a survey email signatures and survey page headers.
        Logo will be resized to 256x140 pixels.
      </p>
    </div>

    <form @submit.prevent="onSubmit">
      <div>
        <rj-company-logo-uploader
          :logo="fileData"
          @file-uploaded="onLogoUpload"
        />

        <div class="flex flex-col items-center justify-center mt-6">
          <vs-button
            v-if="file"
            button="submit"
            size="large"
            class="mb-2"
          >
            Save Logo
          </vs-button>

          <vs-button
            type="flat"
            @click="$emit('skip')"
          >
            Skip for now
          </vs-button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import RjCompanyLogoUploader from '@/components/RjCompanyLogoUploader.vue';

export default {
  name: 'RjClientLogo',
  components: {
    RjCompanyLogoUploader,
  },

  props: {
    clientLogoData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      fileData: this.clientLogoData.fileData,
      file: this.clientLogoData.file,
      uploaded: false,
    };
  },

  mounted() {
    this.$emit('step-changed');
    this.fileData = this.clientLogoData.fileData;
    this.file = this.clientLogoData.file;
  },

  methods: {
    onLogoUpload(payload) {
      if (payload) {
        this.fileData = payload.fileData;
        this.file = payload.file;
      }
    },
    onSubmit() {
      this.$emit('submit', { file: this.file, fileData: this.fileData });
    },
  },
};
</script>
