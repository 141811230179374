<template>
  <vs-prompt
    title="Payment Method"
    accept-text="Save"
    class="rj-confirm-modal"
    :active="active"
    @accept="submit"
    @cancel="close"
    @close="close"
  >
    <form>
      <!-- Name on Card -->
      <vs-input
        v-model="form.nameOnCard"
        v-validate="'required'"
        label="Name on Card"
        name="Name on Card"
        class="w-full"
      />
      <span class="text-sm text-red-500">{{
        errors.first('Name on Card')
      }}</span>

      <!-- Card Number -->
      <vs-input
        v-model="form.cardNumber"
        v-validate="'required'"
        v-facade="creditCardMask"
        label="Card Number"
        name="Card Number"
        class="w-full mt-3"
        @focus="clearOnFocus($event, 'cardNumber')"
        @blur="restoreOnBlur($event, 'cardNumber')"
      />
      <span class="text-sm text-red-500">{{
        errors.first('Card Number')
      }}</span>

      <div class="vx-row mt-3">
        <!-- Expiration Date -->
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.expiryDate"
            v-validate="'required'"
            v-facade="'##/##'"
            name="Expiration Date"
            label="MM / YY"
            class="w-full"
          />
          <span class="text-sm text-red-500">{{
            errors.first('Expiration Date')
          }}</span>
        </div>


        <!-- Security Code -->
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.cvc"
            v-validate="'required'"
            v-facade="'#####'"
            name="CVC"
            label="CVC"
            class="w-full"
          />
          <span class="text-sm text-red-500">{{
            errors.first('CVC')
          }}</span>
        </div>
      </div>
    </form>
  </vs-prompt>
</template>

<script>
import { facade } from 'vue-input-facade';

export default {
  name: 'RjPaymentMethodForm',
  directives: {
    facade,
  },
  props: {
    paymentMethod: {
      type: Object,
      required: false,
      default() {
        return {
          nameOnCard: '',
          last4: '',
          expiryMonth: '',
          expiryYear: '',
          cvc: '',
        };
      },
    },
  },

  data() {
    let data = {};
    if (this.paymentMethod) {
      const {
        nameOnCard, last4, expiryMonth, expiryYear,
      } = { ...this.paymentMethod };
      const expiryYearLast2 = expiryYear.toString().match(/(\d\d)$/)?.[1] || '';
      data = {
        active: true,
        form: {
          nameOnCard: nameOnCard || '',
          cardNumber: `**** **** **** ${last4}`,
          expiryDate: `${expiryMonth}/${expiryYearLast2}`,
          cvc: '',
        },
        creditCardMask: '**** **** **** ####',
      };
    } else {
      data = {
        active: true,
        form: {
          nameOnCard: '',
          cardNumber: '',
          expiryDate: '',
          cvc: '',
        },
        creditCardMask: '#### #### #### ####',
      };
    }
    return data;
  },

  mounted() {
    this.$validator.extend('masked_credit_card', {
      getMessage: () => 'Card number is invalid.',
      validate: (value) => {
        const stripped = value.replace(/\D/g, '');
        if (/\*+/.test(value) && /^\d{4}$/.test(stripped)) {
          return true;
        }
        return /\d{15,16}/.test(stripped);
      },
    });
  },

  methods: {
    clearOnFocus($event, fieldName) {
      const val = $event.target.value;
      if (/\*/.test(val)) {
        if (fieldName === 'cardNumber') {
          this.form.cardNumber = '';
          this.creditCardMask = '#### #### #### ####';
        }
      }
    },

    restoreOnBlur($event, fieldName) {
      const val = $event.target.value;
      if (!val.trim()) {
        if (fieldName === 'cardNumber') {
          this.creditCardMask = '**** **** **** ####';
          this.form.cardNumber = `**** **** **** ${this.paymentMethod?.last4 || ''}`;
        }
      }
    },

    async submit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }
      const payload = { paymentMethod: this.form };
      const paymentMethod = await this.$store.dispatch('partners/updatePaymentMethod', payload);
      const error = this.$store.getters['partners/error'];
      if (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      } else {
        this.$vs.notify({
          title: 'Success',
          text: 'Payment Method saved.',
          color: 'success',
        });
        this.$emit('payment-method-updated', paymentMethod);
        this.close();
      }
    },

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.active = false;
      this.$emit('close');
    },
  },
};
</script>
