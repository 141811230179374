<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Confirm the review sites for your client’s location.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        Which review sites would you like us to monitor and drive your client’s happy customers to for reviews?
        Please make sure your client’s top review sites are as correct and complete as possible.
      </p>
    </div>

    <div>
      <table class="client__review-sites-table">
        <thead>
          <tr>
            <th />
            <th>Review Site</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody v-if="loading">
          <tr
            v-for="n in 4"
            :key="n"
            class="animate-pulse"
          >
            <td>
              <div class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 mr-3" />
            </td>
            <td>
              <div class="w-full h-3 bg-gray-300 rounded-full" />
            </td>
            <td>
              <div class="w-16 h-3 mx-auto bg-gray-300 rounded-full" />
            </td>
            <td>
              <div class="w-6 h-6 mx-auto bg-gray-300 rounded-full" />
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="reviewSite in reviewSites"
            :key="reviewSite.code"
          >
            <td>
              <img
                :src="logoForReviewSite(reviewSite.code)"
                :alt="reviewSite.name"
                class="flex-shrink-0 mr-3"
              >
            </td>
            <td>
              <p>{{ reviewSite.name }}</p>
            </td>
            <td>
              <template v-if="reviewSite.profileUrl">
                <a
                  :href="reviewSite.profileUrl"
                  target="_blank"
                  class="flex items-center justify-center"
                >
                  <span class="text-primary mt-1">Preview</span>
                  <feather-icon
                    icon="ExternalLinkIcon"
                    svg-classes="w-5 h-5 cursor-pointer text-primary ml-2"
                  />
                </a>
              </template>

              <template v-else>
                <span class="text-danger text-center">Missing</span>
              </template>
            </td>
            <td>
              <div class="flex items-center justify-center">
                <feather-icon
                  :icon="reviewSite.profileUrl ? 'Edit3Icon' : 'PlusCircleIcon'"
                  svg-classes="w-6 h-6 cursor-pointer text-primary"
                  @click="reviewSite.profileUrl ? edit(reviewSite) : add(reviewSite)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-6 text-center">
      <p class="text-sm text-gray-600">
        NOTE: There are many other review sites we connect with and you'll be able to add them at a later time.
      </p>

      <div class="flex flex-col justify-center items-center mt-3">
        <vs-button
          size="large"
          :disabled="loading"
          @click="submit"
        >
          Save Review Sites
        </vs-button>
      </div>
    </div>

    <rj-review-site-dialog
      v-if="modal"
      :active.sync="modal"
      :location-review-site="current"
      :review-sites="reviewSites"
      @review-site-updated="onReviewSiteUpdated"
      @review-site-added="onReviewSiteUpdated"
    />
  </section>
</template>

<script>
import RjReviewSiteDialog from '@/views/common/locations/modals/RjReviewSiteDialog.vue';

const logos = {
  bbb: require('@/assets/images/review-sites/BBBRSB.png'),
  facebook: require('@/assets/images/review-sites/FacebookRSB.png'),
  google: require('@/assets/images/review-sites/GoogleRSB.png'),
  truelocal: require('@/assets/images/review-sites/TrueLocalRSB.png'),
  yellowpages: require('@/assets/images/review-sites/YellowPagesRSB.png'),
  yelp: require('@/assets/images/review-sites/YelpRSB.png'),
  yp: require('@/assets/images/review-sites/ypRSB.png'),
};

export default {
  name: 'RjReviewSites',

  components: {
    RjReviewSiteDialog,
  },

  props: {
    clientAddressData: {
      type: Object,
      required: true,
    },

    clientDetailsData: {
      type: Object,
      required: true,
    },

    reviewSitesData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      current: {},
      modal: false,
      action: 'add',
      loading: true,
      reviewSites: this.reviewSitesData,
    };
  },

  async mounted() {
    this.$emit('step-changed');

    // don't fetch review sites again
    if (this.reviewSitesData.length) {
      this.loading = false;
      return;
    }

    const {
      addressCountry,
      addressStreet,
      addressCity,
      addressRegion,
      addressZip,
      phone,
    } = this.clientAddressData;

    const response = await this.$store.dispatch('locations/getLocationReviewSitesForNap', {
      name: this.clientDetailsData.companyName,
      address: `${addressStreet} ${addressCity} ${addressRegion} ${addressZip}`,
      phone,
      country: addressCountry,
    });

    this.loading = false;

    if (!response) {
      return;
    }

    const { locationReviewSites } = response;
    if (locationReviewSites) {
      this.reviewSites = this.normalize(locationReviewSites);
      this.$emit('review-sites-updated', this.reviewSites);
    }
  },

  methods: {
    logoForReviewSite(code) {
      return logos[code] || '';
    },

    submit() {
      this.confirm(() => this.$emit('submit', this.reviewSites));
    },

    skip() {
      this.confirm(() => this.$emit('skip'));
    },

    confirm(callback) {
      return this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'RevenueJump\'s functionality and reporting relies heavily on review sites like these being correct and complete. If needed, you can add more review sites later.',
        accept: callback,
        acceptText: 'Yes',
      });
    },

    add(reviewSite) {
      this.open('add', reviewSite);
    },

    edit(reviewSite) {
      this.open('edit', reviewSite);
    },

    onReviewSiteUpdated(reviewSite) {
      const index = this.reviewSites.findIndex((r) => r.code === reviewSite.code);
      this.reviewSites[index] = reviewSite;
      this.$emit('review-sites-updated', this.reviewSites);
    },

    open(action, reviewSite) {
      this.modal = true;
      this.action = action;
      this.current = reviewSite;
    },

    normalize(reviewSites) {
      return reviewSites.map((site) => ({ ...site, id: site.reviewSiteId }));
    },
  },
};
</script>
