<template>
  <rj-modal @close="close">
    <header class="flex items-center justify-between">
      <vs-button
        color="dark"
        type="flat"
        icon-pack="feather"
        icon="icon-x"
        size="large"
        class="ml-auto"
        @click="close"
      />
    </header>

    <section class="w-full md:max-w-lg justify-center mx-auto">
      <div class="mb-6 text-center">
        <h1 class="text-3xl font-bold">
          Are You Elite Material?
        </h1>
        <p class="text-lg mt-2 text-gray-600">
          Upgrade to the Elite Partner program for just an extra $100/mo to get all these perks:
        </p>
      </div>

      <rj-become-elite-perks />
    </section>

    <footer class="w-full md:max-w-lg justify-center mx-auto">
      <div class="text-center mt-6">
        <vs-button
          v-if="canUpgrade"
          :disabled="!proratedAmount"
          size="large"
          @click="upgradeAlert"
        >
          Upgrade Me
        </vs-button>

        <p
          v-else
          class="text-gray-600"
        >
          <span class="font-bold">Want to upgrade?</span> This is can only be initiated by a partner account user with owner,
          admin, or billing access. Please make the request with them first.
        </p>
      </div>
    </footer>
  </rj-modal>
</template>

<script>
import { format } from 'date-fns';
import { bus } from '@/utils';
import RjModal from '@/layouts/components/RjModal.vue';
import RjBecomeElitePerks from '@/views/partner-dashboard/subscription/modals/RjBecomeElitePerks.vue';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjBecomeEliteModal',

  components: {
    RjModal,
    RjBecomeElitePerks,
  },

  mixins: [Authorizable],

  data() {
    return {
      proratedAmount: '',
      renewalDay: '',
    };
  },

  computed: {
    canUpgrade() {
      return this.$_guard_permissions.billing.edit;
    },
  },

  async mounted() {
    const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForElite');
    this.proratedAmount = this.formatCentsAsDollars(amount);
    this.renewalDay = format(renewalDate, 'do');
  },

  methods: {
    upgradeAlert() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Savvy Move!',
        text: `You will be charged a prorated amount of ${this.proratedAmount} now and upgrade from Certified Partner at $99.00/mo to Elite Partner at $199.00/mo USD beginning on the next ${this.renewalDay} of the month.`,
        acceptText: 'Accept',
        accept: async() => {
          await this.$store.dispatch('partners/upgradeToElite');
          const { partnerId } = this.$store.getters['auth/currentUser'];
          this.$store.dispatch('partners/loadData', partnerId);
          return this.close();
        },
      });
    },

    close() {
      bus.$emit('hide-modal');
    },
  },
};
</script>
