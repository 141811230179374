<template>
  <div class="flex flex-wrap md:flex-no-wrap items-center justify-between md:space-x-8 space-y-8 md:space-y-0">
    <statistics-card-line
      icon="StarIcon"
      :statistic="reputationPerformanceGrade.current"
      statistic-title="Reputation Performance Grade"
      :chart-data="reputationPerformanceGrade.chartData"
      type="area"
      color="rjBlue"
    />

    <statistics-card-line
      icon="MapIcon"
      :statistic="competitorReviewScoreHistory.current"
      statistic-title="Competitor Review Score"
      :chart-data="competitorReviewScoreHistory.chartData"
      type="area"
      color="rjOrange"
    />

    <statistics-card-line
      icon="SmileIcon"
      :statistic="companySatisfactionScore.current"
      statistic-title="Satisfaction Score"
      :chart-data="companySatisfactionScore.charData"
      type="area"
      color="rjGreen"
    />

    <statistics-card-line
      icon="FileTextIcon"
      :statistic="thirtyDaySurvey.current"
      statistic-title="30-Day Surveys"
      :chart-data="thirtyDaySurvey.chartData"
      type="area"
      color="rjRed"
    />
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

export default {
  name: 'RjCompanyAnalytics',

  components: { StatisticsCardLine },

  props: {
    grades: {
      type: Array,
      required: true,
    },

    crs: {
      type: Object,
      required: true,
    },

    satisfactionScore: {
      type: Number,
      required: true,
    },

    surveysSent: {
      type: Number,
      required: true,
    },
  },

  computed: {
    reputationPerformanceGrade() {
      const data = this.grades.map((g) => g.rpg) || [];
      const current = this.grades[this.grades.length - 1].rpg || 0;

      return {
        current,
        chartData: [{ name: 'Reputation Performance Grade', data }],
      };
    },

    competitorReviewScoreHistory() {
      const scores = this.crs.scores.length >= 1 ? this.crs.scores : [0];

      return {
        current: scores[scores.length - 1],
        chartData: [{ name: 'Competitor Review Score', data: scores }],
      };
    },

    companySatisfactionScore() {
      return {
        current: this.satisfactionScore,
        chartData: [{ name: 'Competitor Review Score', data: [] }],
      };
    },

    thirtyDaySurvey() {
      return {
        current: this.surveysSent,
        chartData: [{ name: '30-Day Surveys', data: [] }],
      };
    },
  },
};
</script>
