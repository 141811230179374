<template>
  <div>
    <div class="vx-row mx-auto mt-8">
      <div class="vx-col flex w-full sm:w-1/2">
        <feather-icon
          icon="BoxIcon"
          svg-classes="w-12 h-12 mr-4"
          :style="{ color: colors.blue }"
        />
        <div>
          <h4>In-House High Jump Account</h4>
          <p>
            Use it yourself and/or as a demo account for potential clients.
          </p>
        </div>
      </div>

      <div class="vx-col flex w-full sm:w-1/2">
        <feather-icon
          icon="KeyIcon"
          svg-classes="w-12 h-12 mr-4"
          :style="{ color: colors.yellow }"
        />
        <div>
          <h4>Single Sign-On (SSO)</h4>
          <p>
            Direct access to all your client accounts from one dashboard.
          </p>
        </div>
      </div>
    </div>

    <div class="vx-row mx-auto mt-10">
      <div class="vx-col flex w-full sm:w-1/2">
        <feather-icon
          icon="FilterIcon"
          svg-classes="w-12 h-12 mr-4"
          :style="{ color: colors.purple }"
        />
        <div>
          <h4>Inbound Lead Distribution</h4>
          <p>
            We forward end-user prospect leads to our Elite Partners.
          </p>
        </div>
      </div>

      <div class="vx-col flex w-full sm:w-1/2">
        <feather-icon
          icon="EditIcon"
          svg-classes="w-12 h-12 mr-4"
          :style="{ color: colors.red }"
        />
        <div>
          <h4>Guest Blogging Access</h4>
          <p>
            Submit articles to post on our blog and link back to your site.
          </p>
        </div>
      </div>
    </div>

    <div class="vx-row mx-auto mt-8">
      <div class="vx-col flex mx-auto w-full sm:w-1/2">
        <feather-icon
          icon="DollarSignIcon"
          svg-classes="w-12 h-12 mr-4"
          :style="{ color: colors.green }"
        />
        <div>
          <h4>10% Monthly Bonus</h4>
          <p>
            Combined with commissions, earn up to 50% on clients accounts.
          </p>
        </div>
      </div>
    </div>

    <div class="mt-8 mx-auto text-center">
      <h3 class="text-xl">
        #1 Reason Why You Want to Upgrade Now
      </h3>
      <p class="mt-2 text-lg text-gray-600">
        MONEY! The 10% monthly bonus is ONLY payable on client accounts you add or upgrade while an Elite Partner.
        For example, if you sign up a client while a Certified Partner, then cancel and add them
        while an Elite Partner, the 10% bonus will not apply.
      </p>
    </div>
  </div>
</template>

<script>
import Color from 'color';
import { colors } from '@/../themeConfig';

export default {
  name: 'RjBecomeElitePerks',

  computed: {
    colors() {
      return {
        blue: Color(colors.rjBlue).lightness(70).hex(),
        red: Color(colors.rjRed).lightness(70).hex(),
        green: Color(colors.rjGreen).lightness(70).hex(),
        yellow: Color(colors.rjYellow).lightness(70).hex(),
        purple: Color(colors.rjPurple).lightness(70).hex(),
      };
    },
  },
};
</script>
