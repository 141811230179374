<template functional>
  <div class="mt-6">
    <h1 class="text-3xl mb-6 text-center">
      Filtering Negative Feedback.
    </h1>

    <div>
      <p class="text-lg text-gray-600">
        This setting only applies when asking for online reviews
      </p>
      <p class="text-lg mt-2 text-gray-600">
        <span class="font-bold">Yes</span> -- If your customer is not happy, the application will only ask for their feedback.
        This helps you to avoid negative reviews.
      </p>
      <p class="text-lg mt-2 text-gray-600">
        <span class="font-bold">No</span> -- If your customer is not happy, the application will provide direct links to public review sites.
        This opens you up to getting more negative reviews.
      </p>
    </div>

    <div>
      <h3 class="text-2xl mt-6 mb-3 text-center">
        Should you filter out negative feedback?
      </h3>

      <p class="text-lg text-gray-600">
        It's entirely up to you. Just know that doing so may be against the rules and guidelines
        of review sites like Google and Yelp.
      </p>
      <p class="text-lg mt-2 text-gray-600">
        At RevenueJump, we disagree with these policies as long as the feedback filter is not abused by the company.
        Unhappy customers are more likely to leave reviews than happy customers. This naturally skews review ratings
        towards the negative. The feedback filter, in our opinion, helps to level the playing field and,
        more importantly, gives the company an opportunity to fix any issues before they're made public.
      </p>
    </div>

    <div class="flex justify-center mt-6">
      <vs-button @click="listeners['close']">
        Close
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RjNegativeFeedbackDetails',
};
</script>
