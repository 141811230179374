<template functional>
  <div>
    <header class="flex items-center justify-between">
      <span
        v-if="props.index !== 0 && props.backButton"
        class="flex items-center text-black px-3 py-3 rounded-lg cursor-pointer"
        @click.prevent="listeners['back']"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          svg-classes="w-4 h-4 mr-2"
        />
        Back
      </span>

      <vs-button
        v-if="props.closeButton"
        color="dark"
        type="flat"
        icon-pack="feather"
        icon="icon-x"
        size="large"
        class="ml-auto"
        @click.native="listeners['close']"
      />
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RjWizardStep',

  props: {
    index: {
      type: Number,
      required: false,
      default: 0,
    },

    backButton: {
      type: Boolean,
      required: false,
      default: true,
    },

    closeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
