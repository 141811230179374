<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Add the physical address of your client’s location.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        Enter the address that might appear on their Google My Business page.
      </p>
    </div>

    <form @submit.prevent="onSubmit">
      <!-- Country -->
      <div>
        <div class="flex items-center">
          <vs-radio
            v-model="form.addressCountry"
            v-validate="'required'"
            vs-value="US"
            name="addressCountry"
            class="mr-5"
          >
            United States of America
          </vs-radio>
          <vs-radio
            v-model="form.addressCountry"
            v-validate="'required'"
            name="addressCountry"
            vs-value="CA"
          >
            Canada
          </vs-radio>
        </div>
        <span
          v-show="errors.has('addressCountry')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <!-- Street Address -->
      <div>
        <vs-input
          v-model="form.addressStreet"
          v-validate="'required'"
          label="Street Address*"
          name="addressStreet"
          class="w-full mt-3"
        />
        <span
          v-show="errors.has('addressStreet')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <!-- City -->
      <div>
        <vs-input
          v-model="form.addressCity"
          v-validate="'required'"
          label="City*"
          name="addressCity"
          class="w-full mt-3"
        />
        <span
          v-show="errors.has('addressCity')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <div class="vx-row mt-3">
        <!-- State -->
        <div class="vx-col w-full sm:w-1/2">
          <label class="vs-input--label">State/Province*</label>
          <rj-select
            v-model="form.addressRegion"
            v-validate="'required'"
            :options="states"
            label="name"
            name="addressRegion"
            :reduce="(state) => state.shortCode"
            :clearable="false"
            class="w-full"
            placeholder="Please select..."
          />
          <span
            v-show="errors.has('addressRegion')"
            class="text-danger text-sm"
          >
            This field is required
          </span>
        </div>

        <!-- Zip Code -->
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.addressZip"
            v-validate="'required'"
            v-facade="zipcodeMask"
            label="Zip/Postal Code*"
            name="addressZip"
            class="w-full"
          />
          <span
            v-show="errors.has('addressZip')"
            class="text-danger text-sm"
          >
            This field is required
          </span>
        </div>
      </div>

      <!-- Location Phone Number -->
      <div>
        <vs-input
          v-model="form.phone"
          v-validate="'required'"
          v-facade="'(###) ###-####'"
          label="Location Phone Number*"
          name="phone"
          class="w-full mt-3"
        />
        <span
          v-show="errors.has('phone')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <div class="text-center mt-6">
        <vs-button
          button="submit"
          size="large"
        >
          Save Location
        </vs-button>
      </div>
    </form>
  </section>
</template>

<script>
import { facade } from 'vue-input-facade';

export default {
  name: 'RjClientAddress',
  directives: {
    facade,
  },
  props: {
    clientAddressData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        addressCountry: this.clientAddressData.addressCountry,
        addressStreet: this.clientAddressData.addressStreet,
        addressCity: this.clientAddressData.addressCity,
        addressRegion: this.clientAddressData.addressRegion,
        addressZip: this.clientAddressData.addressZip,
        phone: this.clientAddressData.phone,
      },
    };
  },

  computed: {
    countries() {
      return this.$store.getters['geography/countries'];
    },

    states() {
      const country = this.countries.find((c) => c.countryShortCode === this.form.addressCountry);

      return country.regions;
    },

    zipcodeMask() {
      return this.form.addressCountry === 'US' ? '#####' : 'XXX XXX';
    },
  },

  mounted() {
    this.$emit('step-changed');
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        return this.$emit('submit', this.form);
      }
      return false;
    },
  },
};
</script>
