import { format } from 'date-fns';
import { httpBuildQuery } from '@/utils';
import { LOW_JUMP_CODE } from '@/rj_consts';

export default {
  data() {
    return {
      loading: true,
      wizard: false,
    };
  },

  async mounted() {
    const filters = httpBuildQuery({
      includeSurveyStats: 1,
      filter: JSON.stringify({
        where: { active: true, companyId: this.$route.params.company },
      }),
    });

    await this.$store.dispatch('locations/fetchLocations', filters);
    this.loading = false;
  },

  methods: {
    /**
     * Adds a new location to current company
     *
     * @return {void}
     */
    add() {
      this.plan.code === LOW_JUMP_CODE
        ? this.triggerPlanWarning()
        : this.wizard = true;
    },

    /**
     * Show dialog when user attempts to add a location when in Low Jump plan.
     *
     * @return {void}
     */
    triggerPlanWarning() {
      this.$vs.dialog({
        color: 'primary',
        title: 'High Jump Feature',
        text: 'This company is on the Low Jump plan and additional locations are only available on the High Jump plan. Would you like to upgrade your client?',
        acceptText: 'Upgrade',
        accept: () => this.triggerPaymentWarning(),
      });
    },

    /**
     * Alerts the user that charges will be made.
     *
     * @return {void}
     */
    async triggerPaymentWarning() {
      const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
      const renewalDay = format(renewalDate, 'do');
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Upgrade to High Jump',
        text: `You will be charged a prorated amount of ${this.formatCentsAsDollars(amount)} now and $99.00 USD monthly for this location beginning on the next ${renewalDay} of the month.`,
        acceptText: 'Accept',
        accept: async() => {
          await this.$store.dispatch('companies/upgradeToHighJump', this.$route.params.company);
          this.wizard = true;
        },
      });
    },

    /**
     * Shows a dialog for the user to confirm the delete action
     *
     * @param  {Object} location
     * @return {void}
     */
    removeConfirmation(location) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'This action cannot be undone.',
        acceptText: 'Yes',
        accept: () => this.remove(location),
      });
    },

    /**
     * Removes a location from storage.
     *
     * @param  {Object} location
     * @return {void}
     */
    async remove(location) {
      await this.$store.dispatch('locations/removeLocation', location.id);
    },
  },
};
