<template>
  <div class="grid lg:grid-cols-4 md:grid-cols-2 gap-4">
    <statistics-card-line
      hide-chart
      icon="UsersIcon"
      icon-right
      :statistic="total"
      :statistic-title="total === 1 ? 'Company' : 'Companies'"
      color="rjBlue"
    />

    <statistics-card-line
      hide-chart
      icon="ArrowDownCircleIcon"
      icon-right
      :statistic="numLowJumps"
      :statistic-title="numLowJumps === 1 ? 'Low Jump' : 'Low Jumps'"
      color="rjOrange"
    />

    <statistics-card-line
      hide-chart
      icon="ArrowUpCircleIcon"
      icon-right
      :statistic="numHighJumps"
      :statistic-title="numHighJumps === 1 ? 'High Jump' : 'High Jumps'"
      color="rjGreen"
    />

    <statistics-card-line
      hide-chart
      icon="FileTextIcon"
      icon-right
      :statistic="num30DaySurveys"
      :statistic-title="num30DaySurveys === 1 ? '30-Day Survey' : '30-Day Surveys'"
      color="rjRed"
    />
  </div>
</template>

<script>
import { HIGH_JUMP_CODE, LOW_JUMP_CODE } from '@/rj_consts';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';

export default {
  name: 'RjCompaniesAnalytics',

  components: { StatisticsCardLine },

  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  computed: {
    total() {
      return this.companies.length;
    },

    numLowJumps() {
      return this.companies.filter((c) => c.plan.code === LOW_JUMP_CODE).length;
    },

    numHighJumps() {
      return this.companies.filter((c) => c.plan.code === HIGH_JUMP_CODE).length;
    },

    num30DaySurveys() {
      return this.companies.reduce((acc, cur) => acc + cur.num30DaySurveys, 0);
    },
  },
};
</script>
