<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Connect your client’s RevenueJump account to Google.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        For us to securely connect to and retrieve the reviews from your client's Google My Business profile,
        Google requires authorization. If you have access to your client's GMB profile, click the purple button below.
        You will then be temporarily redirected to a Google authentication screen.
      </p>
    </div>

    <img
      src="@/assets/images/review-sites/revenuejump-to-google.png"
      alt="RevenueJump to Google My Business"
      class="block mx-auto"
    >

    <div class="mt-6 text-center">
      <p class="text-sm text-gray-600">
        NOTE: This connection will only be used to retrieve reviews and you can revoke it any time. Monitoring will begin
        once this connection is made.
      </p>

      <div class="flex flex-col justify-center items-center mt-6">
        <vs-button
          v-if="!authCode"
          size="large"
          @click="getAuthCode"
        >
          Connect to Google
        </vs-button>

        <vs-button
          v-if="authCode"
          size="large"
          @click="next"
        >
          Connected! Continue
        </vs-button>


        <vs-button
          v-if="!authCode"
          type="flat"
          class="mt-2"
          @click="skip"
        >
          Skip for now
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RjGoogleConnector',

  props: {
    googleAuthCode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      authCode: '',
    };
  },

  mounted() {
    this.$emit('step-changed');
    this.authCode = this.googleAuthCode;
  },

  methods: {
    async getAuthCode() {
      try {
        this.authCode = await this.$gAuth.getAuthCode();
        if (/\w+/.test(this.authCode)) {
          this.$emit('google-auth-updated', this.authCode);
        }
      } catch (error) {
        console.error(error);
      }
    },
    next() {
      this.$emit('skip');
    },
    skip() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'RevenueJump\'s functionality and reporting will be extremely limited without connecting to Google My Business.',
        accept: () => this.$emit('skip'),
      });
    },
  },
};
</script>
