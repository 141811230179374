<template>
  <section class="w-full md:w-8/12 justify-center mx-auto">
    <template v-if="details">
      <rj-negative-feedback-details @close="toggleDetails" />
    </template>

    <template v-else>
      <div class="mb-6 text-center">
        <h1 class="text-3xl">
          Finalize the settings for your client's location.
        </h1>
      </div>

      <form
        class="w-full md:max-w-md mx-auto"
        @submit.prevent="onSubmit"
      >
        <div>
          <vs-input
            v-model="form.fromEmail"
            v-validate="'required|email'"
            label="What is the email address from which the surveys will be sent?*"
            name="fromEmail"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('fromEmail')"
            class="text-danger text-sm"
          >
            This field should be a valid email address
          </span>
        </div>

        <div>
          <vs-input
            v-model="form.fromName"
            v-validate="'required'"
            label="What is the first and last name from which the surveys will be sent?*"
            name="fromName"
            class="w-full mt-3"
          />
          <span
            v-show="errors.has('fromName')"
            class="text-danger text-sm"
          >
            This field is required
          </span>
        </div>

        <div
          v-if="!isLowJump"
          class="mt-3"
        >
          <label class="vs-input--label">
            Would you like to filter out negative feedback?
            <a
              href="#"
              class="text-blue-500"
              @click.prevent="toggleDetails"
            >
              Learn More
            </a>
          </label>

          <div class="mt-1">
            <vs-radio
              v-model="form.filterNegativeFeedback"
              vs-value="1"
              class="mr-5"
            >
              Yes
            </vs-radio>

            <vs-radio
              v-model="form.filterNegativeFeedback"
              vs-value="0"
            >
              No
            </vs-radio>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center mt-6">
          <vs-button
            button="submit"
            size="large"
            :disabled="submitting"
          >
            {{ submitting ? 'Saving...' : 'Save Settings' }}
          </vs-button>
        </div>
      </form>
    </template>
  </section>
</template>

<script>
import { LOW_JUMP_CODE } from '@/rj_consts';
import RjNegativeFeedbackDetails from '@/views/partner-dashboard/companies/form-wizard/RjNegativeFeedbackDetails.vue';

export default {
  name: 'RjClientSettings',

  components: {
    RjNegativeFeedbackDetails,
  },

  props: {
    submitting: {
      type: Boolean,
      required: false,
      default: false,
    },

    clientSettingsData: {
      type: Object,
      required: true,
    },

    clientAddressData: {
      type: Object,
      required: true,
    },

    plan: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: {
        fromEmail: this.clientSettingsData.fromEmail,
        fromName: this.clientSettingsData.fromName,
        filterNegativeFeedback: (this.plan === LOW_JUMP_CODE) ? 0 : this.clientSettingsData.filterNegativeFeedback,
      },
      details: false,
      searchNumber: false,
    };
  },

  computed: {
    isLowJump() {
      return this.plan === LOW_JUMP_CODE;
    },
    existingFunnel() {
      return this.$store.getters['funnels/funnels']?.[0];
    },
  },

  mounted() {
    this.$emit('step-changed');
    this.form.fromEmail = this.existingFunnel?.message1EmailFromEmail || '';
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.$emit('submit', this.form);
    },

    toggleDetails() {
      this.details = !this.details;
      this.$emit('toggle-nav');
    },
  },
};
</script>
