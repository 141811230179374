import { format } from 'date-fns';
import { bus } from '@/utils';
import * as codes from '@/rj_consts';
import RjAddCompanyWizard from '@/views/partner-dashboard/companies/RjAddCompanyWizard.vue';
import RjBecomeEliteModal from '@/views/partner-dashboard/subscription/modals/RjBecomeEliteModal.vue';

const events = [
  { name: 'show-modal', handler: '$_events_openModal' },
  { name: 'hide-modal', handler: '$_events_closeModal' },
  { name: 'archive-company', handler: '$_events_archiveCompany' },
  { name: 'upgrade-company-intent', handler: '$_events_upgradeCompanyIntent' },
  { name: 'upgrade-company', handler: '$_events_upgradeCompanyPlan' },
  { name: 'elite-upgrade-cta', handler: '$_events_upgradeEliteAlert' },
  { name: 'downgrade-company', handler: '$_events_downgradeCompanyPlan' },
  { name: 'payment-method-alert', handler: '$_events_paymentMethodAlert' },
];

export default {
  components: {
    'become-elite': RjBecomeEliteModal,
    'add-company': RjAddCompanyWizard,
  },

  data() {
    return {
      psp_modal: null,
      psp_show: false,
      psp_props: {},
    };
  },

  created() {
    for (const { name, handler } of events) {
      bus.$on(name, this[handler]);
    }
  },

  beforeDestroy() {
    for (const { name, handler } of events) {
      bus.$off(name, this[handler]);
    }
  },

  methods: {
    $_events_paymentMethodAlert() {
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Update Payment Method',
        text: 'We do not have a valid payment method on file. Your account is temporarily on hold and you will only have access to the Accounting page.',
        acceptText: 'Update Payment Method',
        accept: () => {},
      });
    },

    $_events_upgradeEliteAlert() {
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Oops!',
        text: 'You are a Certified Partner and this feature is only available for Elite Partners. Would you like to learn more about upgrading to an Elite Partner?',
        acceptText: 'Learn More',
        accept: () => this.$_events_openModal({ modal: 'become-elite' }),
      });
    },

    $_events_upgradeCompanyIntent(company) {
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'High Jump Feature',
        text: 'This company is on the Low Jump plan and this feature is only available on the High Jump plan. Would you like to upgrade your client?',
        acceptText: 'Upgrade',
        accept: () => this.$_events_upgradeCompanyPlan(company),
      });
    },

    /* eslint no-alert: "off" */
    async $_events_upgradeCompanyPlan(company) {
      const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
      const renewalDay = format(renewalDate, 'do');
      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Upgrade to High Jump',
        text: `You will be charged a prorated amount of ${this.formatCentsAsDollars(amount)} now and $99.00 USD monthly for this location beginning on the next ${renewalDay} of the month.`,
        acceptText: 'Accept',
        accept: async() => {
          this.$vs.notify({
            title: 'Please wait.',
            text: 'Upgrading to High Jump...',
            color: 'success',
            time: 4000,
          });
          await this.$store.dispatch('companies/upgradeToHighJump', company.id);
          const error = this.$store.getters['companies/error'];
          if (error) {
            this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
          } else {
            this.$vs.notify({
              title: 'Success',
              text: `${company.name} upgraded to High Jump`,
              color: 'success',
            });
            this.$store.commit('companies/SET_PLAN_UPGRADED', true);
          }
        },
      });
    },

    /* eslint no-alert: "off" */
    async $_events_downgradeCompanyPlan(company) {
      const { renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Please Confirm',
        text: `This account is paid through ${format(renewalDate, 'MMM. do')} and will remain on the High Jump plan until that date. By downgrading you will not receive a refund, prorated or otherwise. You will also lose the commissions you would be earning on this account. \n\n Additionally, your client will lose access to functionality such as review and referral surveys, SMS messaging, additional locations, the feedback filter, posting to social media, and employee performance reporting.`,
        acceptText: 'Accept',
        accept: async() => {
          // set downgradeAt property for company to next renewal date
          const payload = {
            downgradeAt: renewalDate,
          };
          await this.$store.dispatch('companies/updateCompanyDetails', { companyId: company.id, payload });
          const error = this.$store.getters['companies/error'];
          if (error) {
            this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
          } else {
            this.$vs.notify({
              title: 'Success',
              text: `${company.name} will be downgraded on ${format(renewalDate, 'MMM. do')}`,
              color: 'success',
            });
          }
        },
      });
    },

    async $_events_archiveCompany(company) {
      const { renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
      const body = {
        [codes.LOW_JUMP_CODE]: 'Your client will lose access to their dashboard. While their survey history will be archived, their user accounts, survey templates, and other data will be deleted.',
        [codes.HIGH_JUMP_CODE]: `This account is paid through ${format(renewalDate, 'MMM. do')}. By archiving it you will not receive a refund, prorated or otherwise. You will also lose the commissions you would be earning on this account. \n\n Additionally, your client will lose access to their dashboard. While their survey history will be archived, their user accounts, survey templates, and other data will be deleted.`,
      };

      return this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Are you sure?',
        text: body[company.plan.code],
        acceptText: 'Accept',
        accept: async() => {
          this.$vs.notify({
            title: 'Archiving company',
            text: 'Please wait..',
            color: 'success',
            time: 4000,
          });
          await this.$store.dispatch('companies/archive', company.id);
          const error = this.$store.getters['companies/error'];
          if (error) {
            this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
          } else {
            this.$vs.notify({
              title: 'Success',
              text: `${company.name} has been archived.`,
              color: 'success',
            });
            this.$router.replace({ name: 'partner-companies' });
          }
        },
      });
    },

    $_events_openModal({ modal, props = null }) {
      this.psp_show = true;
      this.psp_modal = modal;
      this.psp_props = props;
    },

    $_events_closeModal() {
      this.psp_show = false;
      this.psp_modal = null;
      this.psp_props = {};
    },
  },
};
