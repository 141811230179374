<template>
  <rj-modal ref="modal">
    <rj-wizard-step
      :index="fw_index"
      :back-button="displayBackBtn && !hideNav"
      :close-button="!hideNav"
      @back="$_fw_previous"
      @close="close"
    >
      <component
        :is="fw_step.component"
        :client-details-data="clientDetailsData"
        :client-logo-data="clientLogoData"
        :client-address-data="clientAddressData"
        :client-settings-data="clientSettingsData"
        :review-sites-data="reviewSitesData"
        :google-auth-code="googleAuthCode"
        :facebook-access-token="facebookAccessToken"
        :plan="plan"
        :index="fw_index"
        :submitting="submitting"
        :company-id="companyId"
        @skip="$_fw_skip"
        @toggle-nav="toggleNavigation"
        @submit="(payload) => onSubmit(fw_step.handler, payload)"
        @review-sites-updated="(reviewSites) => setReviewSites(reviewSites)"
        @google-auth-updated="(authCode) => setGoogleAuthCode(authCode)"
        @facebook-auth-updated="(accessToken) => setFacebookAccessToken(accessToken)"
      />
    </rj-wizard-step>
  </rj-modal>
</template>

<script>
import { bus } from '@/utils';
import { LOW_JUMP_CODE } from '@/rj_consts';
import RjModal from '@/layouts/components/RjModal.vue';
import FormWizard from '@/layouts/components/rj-form-wizard/FormWizard';
import RjWizardStep from '@/layouts/components/rj-form-wizard/RjWizardStep.vue';
import RjFinishStep from '@/views/partner-dashboard/companies/form-wizard/RjFinishStep.vue';
import RjClientLogo from '@/views/partner-dashboard/companies/form-wizard/RjClientLogo.vue';
import RjReviewSites from '@/views/partner-dashboard/companies/form-wizard/RjReviewSites.vue';
import RjPlanSelector from '@/views/partner-dashboard/companies/form-wizard/RjPlanSelector.vue';
import RjClientDetails from '@/views/partner-dashboard/companies/form-wizard/RjClientDetails.vue';
import RjClientAddress from '@/views/partner-dashboard/companies/form-wizard/RjClientAddress.vue';
import RjClientSettings from '@/views/partner-dashboard/companies/form-wizard/RjClientSettings.vue';
import RjGoogleConnector from '@/views/partner-dashboard/companies/form-wizard/RjGoogleConnector.vue';
import RjFacebookConnector from '@/views/partner-dashboard/companies/form-wizard/RjFacebookConnector.vue';

export default {
  name: 'RjAddCompanyWizard',

  components: {
    RjModal,
    RjWizardStep,
    RjFinishStep,
    RjClientLogo,
    RjReviewSites,
    RjPlanSelector,
    RjClientDetails,
    RjClientAddress,
    RjClientSettings,
    RjGoogleConnector,
    RjFacebookConnector,
  },

  mixins: [FormWizard],

  data() {
    return {
      submitting: false,
      clientDetailsData: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        website: '',
        categoryId: null,
        otherCategory: null,
      },
      clientLogoData: {
        fileData: null,
        file: null,
      },
      clientAddressData: {
        addressCountry: 'US',
        addressStreet: '',
        addressCity: '',
        addressRegion: '',
        addressZip: '',
        phone: '',
      },
      clientSettingsData: {
        fromEmail: '',
        fromName: '',
        filterNegativeFeedback: 1,
      },
      reviewSitesData: [],
      facebookAccessToken: '',
      googleAuthCode: '',
      companyId: null,
      locationId: null,
      userId: null,
      plan: null,
      country: null,
      hideNav: false,
      steps: [
        {
          code: 'company-plan',
          handler: 'choosePlan',
          component: 'RjPlanSelector',
        },
        {
          code: 'client-details',
          handler: 'clientDetails',
          component: 'RjClientDetails',
        },
        {
          code: 'client-logo',
          component: 'RjClientLogo',
          handler: 'uploadLogo',
        },
        {
          code: 'client-address',
          handler: 'clientAddress',
          component: 'RjClientAddress',
        },
        {
          code: 'review-sites',
          handler: 'reviewSites',
          component: 'RjReviewSites',
        },
        {
          code: 'google',
          handler: 'googleConnect',
          component: 'RjGoogleConnector',
        },
        {
          code: 'facebook',
          handler: 'facebookConnect',
          component: 'RjFacebookConnector',
        },
        {
          code: 'client-settings',
          component: 'RjClientSettings',
          handler: 'clientSettings',
        },
        {
          code: 'finish',
          component: 'RjFinishStep',
          handler: 'sendWelcomeEmail',
        },
      ],
    };
  },

  computed: {
    displayBackBtn() {
      const isHiddenFor = ['client-details', 'finish'];

      return !isHiddenFor.includes(this.fw_step.code);
    },
  },

  methods: {
    onSubmit(method, payload) {
      return this[method](payload);
    },

    /**
     * RjPlanSelector submit handler.
     *
     * @param  {string} plan
     * @return {void}
     */
    choosePlan(plan) {
      this.plan = plan;
      this.$_fw_next();
    },

    /**
     * RjClientDetails submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    async clientDetails(payload) {
      const userAvailable = await this.$store.dispatch('users/checkUserAvailability', payload.email);
      if (userAvailable) {
        this.clientDetailsData = payload;
        this.$_fw_next();
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'We already have that email in our system. Please contact us directly.',
          color: 'danger',
          time: 7000,
        });
      }
    },

    /**
     * RjClientLogo submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    async uploadLogo(payload) {
      // await this.$store.dispatch('companies/updateCompanyLogo', { file: payload.logoFile, companyId: this.companyId });
      this.clientLogoData = {
        fileData: payload.fileData,
        file: payload.file,
      };
      this.$_fw_next();
    },

    /**
     * RjClientAddress submit handler.
     *
     * @param  {void} payload
     * @return {void}
     */
    clientAddress(payload) {
      this.clientAddressData = payload;
      this.$_fw_next();
    },

    /**
     * RjReviewSites submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    reviewSites(reviewSites) {
      this.reviewSitesData = reviewSites;

      this.$_fw_next();
    },

    /**
     * RjGoogleConnector submit handler.
     *
     * @return {void}
     */
    googleConnect() {
      this.$_fw_next();
    },

    /**
     * RjFacebookConnector submit handler.
     *
     * @return {void}
     */
    facebookConnect() {
      this.$_fw_next();
    },

    /**
     * RjClientSettings submit handler.
     *
     * @param  {Object} payload
     * @return {void}
     */
    async clientSettings(payload) {
      this.clientSettingsData = payload;

      try {
        this.submitting = true;

        const companyId = await this.createCompanySkeleton();
        this.companyId = companyId;
        await this.chargePartner(companyId);

        this.$store.dispatch('partners/loadData');
        this.$_fw_next();
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.message || '',
          color: 'danger',
          time: 7000,
        });
      } finally {
        this.submitting = false;
      }
    },

    /**
     * RjFinishStep submit handler.
     *
     * @return {void}
     */
    sendWelcomeEmail() {
      this.close();
    },

    toggleNavigation() {
      this.hideNav = !this.hideNav;
    },

    setReviewSites(reviewSites) {
      this.reviewSitesData = reviewSites;
    },

    setFacebookAccessToken(accessToken) {
      this.facebookAccessToken = accessToken;
    },

    setGoogleAuthCode(authCode) {
      this.googleAuthCode = authCode;
    },

    async chargePartner(companyId) {
      if (this.plan === LOW_JUMP_CODE) {
        await this.$store.dispatch('partners/addLowJump');
      } else {
        await this.$store.dispatch('partners/addHighJump', { companyId });
      }
    },

    async createCompanySkeleton() {
      const { companyId } = await this.$store.dispatch('partners/createNewCompanySkeleton', {
        ...this.clientDetailsData,
        ...this.clientAddressData,
        ...this.clientSettingsData,
        googleAuthCode: this.googleAuthCode,
        facebookAccessToken: this.facebookAccessToken,
        locationReviewSites: this.reviewSitesData.filter((r) => r.profileUrl),
        plan: { code: this.plan },
      });

      await this.$store.dispatch('companies/updateCompanyLogo', {
        companyId,
        file: this.clientLogoData.file,
      });

      return companyId;
    },

    close() {
      if (this.fw_index === 0 || this.fw_index === 8) {
        return bus.$emit('hide-modal');
      }

      // The Accept and Cancel actions are inverted because
      // it is not possible to change the buttons position in the UI.
      return this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Are You Sure?',
        text: 'You will lose all unsaved changes.',
        cancelText: 'Accept',
        buttonCancel: 'filled',
        cancel: () => bus.$emit('hide-modal'),
        acceptText: 'Cancel',
        buttonAccept: 'border',
      });
    },
  },
};
</script>
