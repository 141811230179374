import { httpBuildQuery, bus } from '@/utils';
import Authorizable from '@/mixins/Authorizable';

export default {
  mixins: [Authorizable],

  data() {
    return {
      loading: true,
      modal: false,
      selected: null,
      licenses: 0,
      usersPackModal: false,
    };
  },

  computed: {
    users() {
      return this.$store.getters['users/users'];
    },

    counts() {
      return {
        users: this.users.length,
        locations: this.$store.getters['companies/locationsCount'],
        usersAddOns: this.$store.getters['companies/extraUsersAddOns'].length,
      };
    },

    subscription() {
      return this.$store.getters['partners/subscription'];
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      const companyId = parseInt(this.$route.params.company, 10);

      const filters = httpBuildQuery({
        includeSurveyStats: 1,
        filter: JSON.stringify({
          where: { active: true, companyId },
          include: [
            {
              relation: 'roleMappings',
              scope: { include: ['role'] },
            },
          ],
        }),
      });

      await this.$store.dispatch('users/fetchUsers', filters);
      this.$store.dispatch('companies/withCounts', companyId);
      this.loading = false;
    },

    addUser() {
      if (this.canAddUser()) {
        this.toggleUserModal();
        return;
      }

      this.isLowJump ? this.triggerUpgradeIntent() : this.buyUsersPackIntent();
    },

    async create(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        const { error, errorMessage, ...user } = await this.$store.dispatch('users/createUser', payload);
        if (error) {
          this.$vs.notify({ title: 'Error', text: errorMessage || error, color: 'danger' });
          return;
        }
        await this.updateLocationsAccess(user, locationAccessControl);
        this.$vs.notify({ title: 'Success', text: 'User created.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async update(form) {
      try {
        const { locationAccessControl, ...payload } = form;
        const user = await this.$store.dispatch('users/updateUser', { userId: form.id, payload });
        await this.updateLocationsAccess(user, locationAccessControl);

        this.$vs.notify({ title: 'Success', text: 'User updated.', color: 'success' });
        this.modal = false;
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: error, color: 'danger' });
      }
    },

    async updateLocationsAccess(user, locations) {
      await this.$store.dispatch('users/updateLocationAccessControls', { userId: user.id, locations });
    },

    removeConfirmation(user) {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'You are about to delete this user and this action cannot be undone.',
        acceptText: 'Delete',
        accept: () => this.remove(user),
      });
    },

    async remove(user) {
      try {
        await this.$store.dispatch('users/deleteUser', user.id);
        this.$vs.notify({ title: 'Success', text: 'User deleted.', color: 'success' });
      } catch (error) {
        this.$vs.notify({ title: 'Error', text: 'Error while deleting the user.', color: 'danger' });
      }
    },

    buyUsersPackIntent() {
      this.$vs.dialog({
        type: 'alert',
        color: 'primary',
        title: 'Limit Reached',
        text: 'This company has reached the maximum number of users. Either delete a user to make room for another, or upgrade with an additional 5-pack of users for $10/month.',
        acceptText: 'Upgrade',
        accept: () => {
          this.usersPackModal = true;
        },
      });
    },

    triggerUpgradeIntent() {
      bus.$emit('upgrade-company-intent', this.company);
    },

    canAddUser() {
      // eslint-disable-next-line
      return this.guard_company_plan_allows('USER_ALLOCATION', this.counts.users, (allocation, usersCount) => {
        this.licenses = (allocation * this.counts.locations) + (5 * this.counts.usersAddOns);
        return this.licenses > usersCount;
      });
    },

    /**
     * Toggles the user modal visibility.
     *
     * @param  {Object} user
     * @return {void}
     */
    toggleUserModal(user = null) {
      this.modal = !this.modal;
      this.selected = user;
    },
  },
};
