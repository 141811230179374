<template>
  <vs-table
    ref="table"
    search
    pagination
    :data="companies"
    :max-items="itemsPerPage"
    :no-data-text="'No Companies'"
    @selected="(company) => $emit('selected', company)"
  >
    <template #header>
      <div class="flex items-center flex-grow justify-between">
        <div class="flex items-center data-list-btn-container">
          <slot name="actions" />
        </div>

        <slot name="filters">
          <div class="cursor-pointer mr-4 items-per-page-handler">
            <vs-dropdown vs-trigger-click>
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">
                  {{ currentTablePage * itemsPerPage - (itemsPerPage - 1) }} - {{ companies.length - currentTablePage * itemsPerPage > 0 ? currentTablePage * itemsPerPage : companies.length }} of {{ companies.length }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  svg-classes="h-4 w-4"
                />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=5">
                  <span>5</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </slot>
      </div>
    </template>

    <template slot="thead">
      <slot name="table-head" />
    </template>

    <template slot-scope="{ data }">
      <tbody>
        <vs-tr
          v-for="(company, index) in data"
          :key="index"
          :data="company"
          :title="company.name"
        >
          <slot
            name="table-row"
            :company="company"
          />
        </vs-tr>
      </tbody>
    </template>
  </vs-table>
</template>

<script>
export default {
  name: 'RjCompaniesList',

  props: {
    companies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      itemsPerPage: 10,
      isMounted: false,
    };
  },

  computed: {
    currentTablePage() {
      return (this.isMounted) ? this.$refs.table.currentx : 0;
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>
