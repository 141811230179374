var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row mx-auto mt-8"},[_c('div',{staticClass:"vx-col flex w-full sm:w-1/2"},[_c('feather-icon',{style:({ color: _vm.colors.blue }),attrs:{"icon":"BoxIcon","svg-classes":"w-12 h-12 mr-4"}}),_vm._m(0)],1),_c('div',{staticClass:"vx-col flex w-full sm:w-1/2"},[_c('feather-icon',{style:({ color: _vm.colors.yellow }),attrs:{"icon":"KeyIcon","svg-classes":"w-12 h-12 mr-4"}}),_vm._m(1)],1)]),_c('div',{staticClass:"vx-row mx-auto mt-10"},[_c('div',{staticClass:"vx-col flex w-full sm:w-1/2"},[_c('feather-icon',{style:({ color: _vm.colors.purple }),attrs:{"icon":"FilterIcon","svg-classes":"w-12 h-12 mr-4"}}),_vm._m(2)],1),_c('div',{staticClass:"vx-col flex w-full sm:w-1/2"},[_c('feather-icon',{style:({ color: _vm.colors.red }),attrs:{"icon":"EditIcon","svg-classes":"w-12 h-12 mr-4"}}),_vm._m(3)],1)]),_c('div',{staticClass:"vx-row mx-auto mt-8"},[_c('div',{staticClass:"vx-col flex mx-auto w-full sm:w-1/2"},[_c('feather-icon',{style:({ color: _vm.colors.green }),attrs:{"icon":"DollarSignIcon","svg-classes":"w-12 h-12 mr-4"}}),_vm._m(4)],1)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("In-House High Jump Account")]),_c('p',[_vm._v(" Use it yourself and/or as a demo account for potential clients. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Single Sign-On (SSO)")]),_c('p',[_vm._v(" Direct access to all your client accounts from one dashboard. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Inbound Lead Distribution")]),_c('p',[_vm._v(" We forward end-user prospect leads to our Elite Partners. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Guest Blogging Access")]),_c('p',[_vm._v(" Submit articles to post on our blog and link back to your site. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("10% Monthly Bonus")]),_c('p',[_vm._v(" Combined with commissions, earn up to 50% on clients accounts. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8 mx-auto text-center"},[_c('h3',{staticClass:"text-xl"},[_vm._v(" #1 Reason Why You Want to Upgrade Now ")]),_c('p',{staticClass:"mt-2 text-lg text-gray-600"},[_vm._v(" MONEY! The 10% monthly bonus is ONLY payable on client accounts you add or upgrade while an Elite Partner. For example, if you sign up a client while a Certified Partner, then cancel and add them while an Elite Partner, the 10% bonus will not apply. ")])])
}]

export { render, staticRenderFns }