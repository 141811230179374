<template>
  <section class="w-full md:w-11/12 justify-center mx-auto">
    <h1 class="text-center">
      Add Company: Select your client’s unlimited plan.
    </h1>

    <div class="flex flex-col mt-6">
      <table class="pricing-table">
        <thead>
          <tr>
            <th />
            <th>Low Jump</th>
            <th>High Jump</th>
          </tr>

          <tr>
            <th />
            <th>
              <vs-button
                @click="choose(LOW_JUMP_CODE)"
              >
                Buy Now
              </vs-button>
            </th>
            <th>
              <vs-button
                @click="choose(HIGH_JUMP_CODE)"
              >
                Buy Now
              </vs-button>
            </th>
          </tr>

          <tr>
            <th />
            <th>
              <p class="font-semibold text-base">
                $0/mo (reg. $52)
              </p>
            </th>
            <th>
              <p class="font-semibold text-base">
                $99/mo (reg. $332)
              </p>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <p class="font-bold">
                Ready-to-Go Funnels
              </p>
            </td>
            <td>Testimonials</td>
            <td>Testimonials, Reviews & Referrals</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Surveys
              </p>
            </td>
            <td>Unlimited</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Messaging
              </p>
            </td>
            <td>Email</td>
            <td>Email & SMS</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                SMS Credits
              </p>
            </td>
            <td />
            <td>500</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Funnels
              </p>
            </td>
            <td>1</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Users + Employees
              </p>
            </td>
            <td>1 + 0</td>
            <td>5 + Unlimited</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Review Monitoring & Alerts
              </p>
            </td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Competitor Tracker
              </p>
            </td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Feedback Filter
              </p>
            </td>
            <td />
            <td>Yes</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Website Widget
              </p>
            </td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Employee Performance Reporting
              </p>
            </td>
            <td />
            <td>Yes</td>
          </tr>

          <tr>
            <td>
              <p class="font-bold">
                Recurring Commissions for You
              </p>
            </td>
            <td />
            <td>Yes</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { HIGH_JUMP_CODE, LOW_JUMP_CODE } from '@/rj_consts';

export default {
  name: 'RjPlanSelector',

  data() {
    return {
      HIGH_JUMP_CODE,
      LOW_JUMP_CODE,
      proratedAmount: '',
      renewalDay: '',
    };
  },

  async mounted() {
    const { amount, renewalDate } = await this.$store.dispatch('partners/getProratedAmountForNewHighJump');
    this.proratedAmount = this.formatCentsAsDollars(amount);
    this.renewalDay = format(renewalDate, 'do');
  },

  methods: {
    async choose(plan) {
      if (plan === this.LOW_JUMP_CODE) {
        return this.$emit('submit', plan);
      }

      return this.$vs.dialog({
        type: 'alert',
        title: 'Please Confirm',
        text: `You will be charged a prorated amount of ${this.proratedAmount} now and $99.00 USD monthly for this location beginning on the next ${this.renewalDay} of the month.`,
        accept: () => this.$emit('submit', plan),
      });
    },
  },
};
</script>
