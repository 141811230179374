<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Tell us about your client.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        This info will be used to create a user account for your client and may
        be shown in survey email signatures, survey page footers, etc.
      </p>
    </div>

    <form @submit.prevent="onSubmit">
      <!-- Company Name -->
      <div>
        <vs-input
          v-model="form.companyName"
          v-validate="'required'"
          label="Company Name*"
          name="companyName"
          class="w-full"
        />
        <span
          v-show="errors.has('companyName')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <div class="vx-row mt-3">
        <!-- First Name -->
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.firstName"
            v-validate="'required'"
            label="First Name*"
            name="firstName"
            class="w-full"
          />
          <span
            v-show="errors.has('firstName')"
            class="text-danger text-sm"
          >
            This field is required
          </span>
        </div>

        <!-- Last Name -->
        <div class="vx-col w-full sm:w-1/2">
          <vs-input
            v-model="form.lastName"
            v-validate="'required'"
            label="Last Name*"
            name="lastName"
            class="w-full"
          />
          <span
            v-show="errors.has('lastName')"
            class="text-danger text-sm"
          >
            This field is required
          </span>
        </div>
      </div>

      <!-- Email -->
      <div>
        <vs-input
          v-model="form.email"
          v-validate="'required|email'"
          label="Email Address*"
          name="email"
          class="w-full mt-3"
        />
        <span
          v-show="errors.has('email')"
          class="text-danger text-sm"
        >
          This field should be a valid email address
        </span>
      </div>

      <!-- Phone -->
      <div>
        <vs-input
          v-model="masked.phone"
          v-validate="'required'"
          v-facade="'(###) ###-####'"
          label="Phone Number*"
          name="phone"
          class="w-full mt-3"
        />
        <span
          v-show="errors.has('phone')"
          class="text-danger text-sm"
        >
          This field is required
        </span>
      </div>

      <!-- Website -->
      <vs-input
        v-model="form.website"
        label="Website"
        class="w-full mt-3"
      />

      <!-- Category -->
      <div class="mt-3">
        <label class="vs-input--label">Category</label>
        <rj-select
          v-model="form.categoryId"
          :options="categories"
          :reduce="category => category.id"
          class="w-full"
          :clearable="false"
          placeholder="Choose a category..."
        />
      </div>

      <!-- Other Category -->
      <vs-input
        v-if="form.categoryId === 1"
        v-model="form.otherCategory"
        label="Other Category"
        class="w-full mt-3"
      />

      <div class="text-center mt-6">
        <vs-button
          button="submit"
          size="large"
        >
          Save Client Info
        </vs-button>
      </div>
    </form>
  </section>
</template>

<script>
import { facade } from 'vue-input-facade';

export default {
  name: 'RjClientDetails',
  directives: {
    facade,
  },
  props: {
    clientDetailsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      masked: {
        phone: this.clientDetailsData.phone,
      },
      form: {
        companyName: this.clientDetailsData.companyName,
        firstName: this.clientDetailsData.firstName,
        lastName: this.clientDetailsData.lastName,
        email: this.clientDetailsData.email,
        phone: this.clientDetailsData.phone,
        website: this.clientDetailsData.website,
        categoryId: this.clientDetailsData.categoryId,
        otherCategory: this.clientDetailsData.otherCategory,
      },
    };
  },

  computed: {
    categories() {
      return this.$store.getters['revenuejump/categories'].map((c) => ({
        label: c.name,
        id: c.id,
      }));
    },
  },

  async mounted() {
    this.$emit('step-changed');
  },

  methods: {
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        return this.$emit('submit', this.form);
      }
      return false;
    },

    cenas(val) {
      this.form.phone = val;
      console.log(val);
    },
  },
};
</script>
