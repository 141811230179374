<template>
  <div class="w-full sm:w-1/2 px-4 mb-4">
    <vx-card title="Current Invoice">
      <table class="table__invoice-details">
        <thead>
          <tr>
            <th>PLAN</th>
            <th class="text-right">
              AMOUNT
            </th>
            <th class="text-right">
              QTY
            </th>
            <th class="text-right">
              SUBTOTAL
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="lineItem in upcomingInvoice.lineItems">
            <tr :key="lineItem.id">
              <td>
                <p class="font-bold">
                  {{ lineItem.description }}
                </p>
              </td>
              <td>
                <div class="text-right">
                  {{ lineItem.code === "discount" ? "" : formatCentsAsDollars(lineItem.unitAmount) }}
                </div>
              </td>
              <td>
                <div class="text-right">
                  {{ lineItem.quantity }}
                </div>
              </td>
              <td>
                <p class="text-right">
                  {{ formatCentsAsDollars(lineItem.amount) }}
                </p>
              </td>
            </tr>
            <template v-if="lineItem.details">
              <tr :key="`dtl-${lineItem.id}`">
                <td colspan="4">
                  <div
                    class="rounded-lg pl-6 py-2 pr-2"
                    :style="{ 'background-color': Color(themeColors.rjPurple).lightness(93).hex() }"
                  >
                    <span
                      v-if="lineItem.details.companies"
                      class="font-semibold"
                    >Companies:</span>
                    <span v-if="hasValidPaymentMethod">
                      <span
                        v-for="(company, index) in lineItem.details.companies"
                        :key="`cmp-${company.id}`"
                      >&nbsp;<router-link
                        class="hover:underline text-black"
                        :to="{ name: `${dashboard}-company`, params: { company: company.id, slug: 'general' } }"
                        target="_blank"
                      >{{ company.name }}<span v-if="company.count > 1"> ({{ company.count }})</span><span v-if="index !== lineItem.details.companies.length - 1">,</span>
                      </router-link>
                      </span>
                      <p
                        v-if="lineItem.showSavings"
                        class="pt-2"
                      >
                        <span class="font-semibold">Monthly Savings:</span> {{ formatCentsAsDollars(lineItem.quantity * retailValueForCode(lineItem.code)) }}
                      </p>
                    </span>
                    <span v-else>
                      <span
                        v-for="(company, index) in lineItem.details.companies"
                        :key="`cmp-${company.id}`"
                      > {{ company.name }}<span v-if="company.count > 1"> ({{ company.count }})</span><span v-if="index !== lineItem.details.companies.length - 1">,</span></span>
                      <p
                        v-if="lineItem.showSavings"
                        class="pt-2"
                      >
                        <span class="font-semibold">Monthly Savings:</span> {{ formatCentsAsDollars(lineItem.quantity * retailValueForCode(lineItem.code)) }}
                      </p>
                    </span>
                    <span v-if="lineItem.details.coupon">{{ lineItem.details.coupon.percent_off ? `${lineItem.details.coupon.percent_off}% ${lineItem.details.coupon.name} - ` : "" }} {{ lineItem.details.coupon.description }}
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <tr class="tr__total">
            <td colspan="4">
              <p
                class="font-semibold"
                :style="{
                  color: Color(themeColors.rjPurple)
                    .lightness(70)
                    .hex(),
                }"
              >
                TOTAL
              </p>
            </td>
          </tr>
          <tr class="tr__total-amount">
            <td class="text-left">
              <vs-button
                v-if="dashboard === 'admin'"
                type="border"
                class="h-8"
                title="Discount"
                icon="icon-chevron-down"
                icon-pack="feather"
                @click="toggleDiscountModal"
              />
            </td>
            <td colspan="3">
              <p class="text-2xl font-bold">
                {{ formatCentsAsDollars(upcomingInvoice.total) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </vx-card>
    <rj-partner-discount-modal
      v-if="discountModal"
      :active.sync="discountModal"
      :discount="discount"
      @discount-applied="onDiscountApplied"
    />
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import { formatCentsAsDollars } from '@/utils';
import {
  HIGH_JUMP_CODE, HIGH_JUMP_RETAIL_VALUE, LOW_JUMP_CODE, LOW_JUMP_RETAIL_VALUE,
} from '@/rj_consts';
import RjPartnerDiscountModal from '@/views/common/partners/modals/RjPartnerDiscountModal.vue';

export default {
  name: 'RjUpcomingInvoice',
  components: { RjPartnerDiscountModal },
  props: {
    upcomingInvoice: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      themeColors,
      Color,
      discountModal: false,
    };
  },
  computed: {
    auth() {
      return { user: this.$store.getters['auth/currentUser'] };
    },
    hasValidPaymentMethod() {
      return this.$store.getters['partners/hasValidPaymentMethod'];
    },
    dashboard() {
      const currentRole = this.$store.getters['auth/currentRole'];
      if (/^Admin/.test(currentRole)) {
        return 'admin';
      }
      if (/^Partner/.test(currentRole)) {
        return 'partner';
      }
      return '';
    },
    discount() {
      return this.upcomingInvoice.lineItems.find((item) => item.code === 'discount');
    },
  },
  methods: {
    formatCentsAsDollars,
    retailValueForCode(code) {
      switch (code) {
      case LOW_JUMP_CODE:
        return LOW_JUMP_RETAIL_VALUE;
      case HIGH_JUMP_CODE:
        return HIGH_JUMP_RETAIL_VALUE;
      default:
        return 0;
      }
    },
    toggleDiscountModal() {
      this.discountModal = !this.discountModal;
    },
    onDiscountApplied() {
      this.$emit('discount-applied');
    },
  },
};
</script>
