<template>
  <div class="w-full flex sm:w-1/2 lg:w-1/4 px-4 mb-4">
    <vx-card class="rj-invoice-card">
      <div class="flex items-center justify-between mb-4">
        <div class="mr-4">
          <h2>{{ formatCentsAsDollars(commission.totalBaseAmountWithoutProrations) }}</h2>
          <p>{{ commissionRate }} Commissions</p>
        </div>
        <feather-icon
          icon="DollarSignIcon"
          class="p-3 inline-flex rounded-full"
          :style="{
            color: themeColors.rjOrange,
            'background-color': Color(themeColors.rjOrange)
              .lightness(93)
              .hex(),
          }"
        />
      </div>

      <div class="mt-auto">
        <p>{{ commissionMessage }}</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import { formatCentsAsDollars } from '@/utils';

export default {
  name: 'RjCommission',
  props: {
    numHighJumps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      themeColors,
      Color,
    };
  },
  computed: {
    commission() {
      return this.$store.getters['partners/commission'];
    },
    commissionRate() {
      const h = this.numHighJumps;
      if (!h || h <= 25) {
        return '20%';
      }
      if (h >= 26 && h <= 99) {
        return '30%';
      }
      if (h >= 100) {
        return '40%';
      }
      return '0%';
    },
    commissionMessage() {
      const h = this.numHighJumps;
      if (!h) {
        return 'Get a 20% commission by adding just 1 High Jump!';
      }
      if (h > 0 && h < 26) {
        const diff = 26 - h;
        return `Increase to 30% with ${diff} more High Jumps.`;
      }
      if (h >= 25 && h < 100) {
        const diff = 100 - h;
        return `Increase to 40% with ${diff} more High Jumps.`;
      }
      return '';
    },
  },
  methods: {
    formatCentsAsDollars,
  },
};
</script>
