<template>
  <vs-prompt
    title="Discount/Credit"
    :active="active"
    :buttons-hidden="true"
    accept-text="acceptBtnText"
    :style="{ zIndex: 51002 }"
    class="rj-confirm-modal"
    @accept="submit"
    @close="close"
  >
    <form>
      <div class="vx-row mt-3">
        <!-- Name -->
        <div class="vx-col w-full">
          <label class="vs-input--label block mt-3">Name</label>
          <vs-radio
            :value="form.name"
            vs-value="Discount"
            vs-name="name"
            class="text-base mt-1 pr-4"
            @input="(value) => form.name = value"
          >
            Discount
          </vs-radio>
          <vs-radio
            :value="form.name"
            vs-value="Credit"
            vs-name="name"
            class="text-base mt-1"
            @input="(value) => form.name = value"
          >
            Credit
          </vs-radio>
        </div>
        <!-- Description -->
        <div class="vx-col w-full">
          <label class="vs-input--label block mt-3">Description</label>
          <vs-textarea
            v-model="form.description"
            v-validate="'required'"
            name="Description"
            height="8rem"
            class="w-full p-3 mb-0"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Description') }}
          </span>
        </div>
        <!-- Type (Amount or Percentage) -->
        <div class="vx-col w-full">
          <label class="vs-input--label block mt-3">Type</label>
          <vs-radio
            ref="type"
            :value="form.type"
            vs-value="amount"
            vs-name="type"
            class="text-base mt-1 pr-4"
            @input="(value) => setAmount()"
          >
            Amount
          </vs-radio>
          <vs-radio
            :value="form.type"
            vs-value="percent"
            vs-name="type"
            class="text-base mt-1 pr-4"
            @input="(value) => setPercent()"
          >
            Percent
          </vs-radio>
        </div>
        <!-- Amount -->
        <div
          v-show="form.type === 'amount'"
          class="vx-col w-full"
        >
          <vs-input
            v-model="form.amount"
            v-facade="['-$######.##', '-$#####.##', '-$####.##', '-$###.##', '-$##.##', '-$#.##']"
            v-validate="'required_if:type,amount'"
            name="Amount"
            label="Amount"
            class="w-full"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Amount') }}
          </span>
        </div>
        <!-- Percent -->
        <div
          v-show="form.type === 'percent'"
          class="vx-col w-full"
        >
          <vs-input
            v-model="form.percent"
            v-validate="'required_if:type,percent|percent'"
            v-facade="['-###%', '-##%', '-#%']"
            name="Percent"
            label="Percent"
            class="w-full"
          />
          <span class="text-sm text-red-500">
            {{ errors.first('Percent') }}
          </span>
        </div>
        <div class="vx-col w-full">
          <label class="vs-input--label block mt-3">Invoices</label>
          <div class="w-1/3">
            <rj-input-number
              v-model.number="form.numInvoices"
              :step="1"
              :min="0"
            />
          </div>
          <span class="text-xs">Set to “0” for unlimited invoices.</span>
        </div>
      </div>
      <div class="mt-3 flex flex-row justify-end">
        <vs-button
          v-if="mode === 'edit' && !saving"
          type="border"
          @click="remove"
        >
          {{ deleteBtnLabel }}
        </vs-button>
        <vs-button
          v-if="!pristine && !deleting"
          class="ml-2"
          @click="submit"
        >
          {{ saveBtnLabel }}
        </vs-button>
      </div>
    </form>
  </vs-prompt>
</template>

<script>
import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import { facade } from 'vue-input-facade';
import { isPristine } from '@/utils';
import RjInputNumber from '@/components/RjInputNumber.vue';
import Authorizable from '@/mixins/Authorizable';

export default {
  name: 'RjPartnerDiscountModal',
  components: { RjInputNumber },
  directives: {
    facade,
  },
  mixins: [Authorizable],

  props: {
    active: {
      type: Boolean,
      requried: true,
    },

    partnerId: {
      type: Number,
      required: false,
      default: null,
    },

    discount: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      form: {
        name: 'Discount',
        description: '',
        amount: '',
        percent: '',
        numInvoices: 1,
        type: 'amount',
      },
      old: {},
      pristine: true,
      saving: false,
      deleting: false,
    };
  },

  computed: {
    acceptBtnText() {
      return this.mode === 'create' ? 'Create' : 'Save';
    },

    mode() {
      return this.discount ? 'edit' : 'create';
    },

    saveBtnLabel() {
      return this.saving ? 'Saving...' : 'Save';
    },

    deleteBtnLabel() {
      return this.deleting ? 'Deleting...' : 'Delete';
    },
  },

  watch: {
    form: {
      handler: 'hasChanges',
      deep: true,
    },
  },

  created() {
    if (this.discount === null) {
      this.buildEmptyFormObject();
    } else {
      this.buildFormObject(this.discount);
    }

    this.$validator.extend('percent', {
      getMessage: () => 'Percent must be 100 or less',
      validate: (value) => {
        if (!value) {
          return true;
        }
        if (typeof value === 'number' && value <= 100) {
          return true;
        }
        return parseInt(value.replace(/\D/g, ''), 10) <= 100;
      },
    });
  },

  methods: {
    setAmount() {
      this.form.amount = '';
      this.form.type = 'amount';
    },

    setPercent() {
      this.form.percent = '';
      this.form.type = 'percent';
    },

    /**
     * Decides which action the modal should take.
     *
     * @return {void}
     */
    async submit() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      this.create();
    },

    /**
     * Create a new User.
     *
     * @return {void}
     */
    async create() {
      let amount;
      let percent;
      if (this.form.type === 'amount') {
        amount = this.form.amount.replace(/\D/g, '');
        amount = Math.abs(parseInt(amount, 10) / 100);
      }
      if (this.form.type === 'percent') {
        percent = this.form.percent.replace(/\D/g, '');
        percent = Math.abs(parseInt(percent, 10));
      }

      const payload = {
        discount: {
          name: this.form.name,
          description: this.form.description,
          amount,
          percent,
          numInvoices: this.form.numInvoices,
        },
        partnerId: this.$store.getters['partners/partner'].id,
      };
      this.saving = true;
      try {
        const { success } = await this.$store.dispatch('partners/createDiscount', payload);
        if (success) {
          this.$vs.notify({ title: 'Success', text: 'Discount applied.', color: 'success' });
          this.$emit('discount-applied');
          this.close();
        }
      } finally {
        this.saving = false;
      }
    },

    async remove() {
      this.deleting = true;
      try {
        const payload = { partnerId: this.$store.getters['partners/partner'].id };
        const { success } = await this.$store.dispatch('partners/deleteDiscount', payload);
        if (success) {
          this.$vs.notify({ title: 'Success', text: 'Discount deleted.', color: 'success' });
          this.$emit('discount-applied');
          this.close();
        }
      } finally {
        this.deleting = false;
      }
    },

    /**
     * Updates the current User.
     *
     * @return {void}
     */
    async update() {
      console.log('update');
      // this.$emit('update-user', this.form);
    },

    buildEmptyFormObject() {
      this.old = _cloneDeep(this.form);
    },

    /**
     * Sets the form values with the given user data
     *
     * @param  {Object} user
     * @return {void}
     */
    buildFormObject(discount) {
      const { details: { coupon } } = discount;
      this.form = {
        name: coupon.name,
        description: coupon.metadata.description,
        amount: coupon.amount_off ? `-${coupon.amount_off}` : '',
        percent: coupon.percent_off ? `-${coupon.percent_off}%` : '',
        numInvoices: coupon.duration_in_months ?? 0,
        type: coupon.amount_off ? 'amount' : 'percent',
      };

      this.old = _cloneDeep(this.form);
    },

    /**
     * Checks if the form has been changed.
     *
     * @param  {Object} original
     * @return {void}
     */
    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    hasChanges: _debounce(function(current) {
      console.log(current);
      console.log(this.old);
      this.pristine = isPristine(current, this.old);
    }, 150),

    /**
     * Programatic way of closing the modal.
     *
     * @return {void}
     */
    close() {
      this.$emit('update:active', false);
    },
  },
};
</script>
