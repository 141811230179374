<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Connect your client’s RevenueJump account to Facebook.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        Facebook requires authorization for us to securely connect and retrieve the reviews from
        your client's Facebook page. If you have access to your client's Facebook page, click the purple button below.
        You will then be temporarily redirected to a Facebook authentication screen.
      </p>
    </div>

    <img
      src="@/assets/images/review-sites/revenuejump-to-facebook.png"
      alt="RevenueJump to Facebook"
      class="block mx-auto"
    >

    <div class="mt-6 text-center">
      <p class="text-sm text-gray-600">
        NOTE: This connection will only be used to retrieve reviews and you can revoke it any time. Monitoring will begin
        once this connection is made.
      </p>

      <div class="flex flex-col justify-center items-center mt-6">
        <v-facebook-login
          v-if="!accessToken"
          ref="fb-login-btn"
          :app-id="appId"
          :login-options="{ scope: 'pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_metadata,pages_manage_engagement,public_profile,email', return_scopes: true }"
          class="hidden"
          @sdk-init="handleSdkInit"
          @login="handleLogin"
        />

        <vs-button
          v-if="!accessToken"
          size="large"
          @click="facebook.login()"
        >
          Connect to Facebook
        </vs-button>

        <vs-button
          v-if="accessToken"
          size="large"
          @click="next"
        >
          Connected! Continue
        </vs-button>


        <vs-button
          type="flat"
          class="mt-2"
          @click="skip"
        >
          Skip for now
        </vs-button>
      </div>
    </div>
  </section>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component';

export default {
  name: 'RjFacebookConnector',

  components: {
    VFacebookLogin,
  },

  props: {
    facebookAccessToken: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      accessToken: '',
      facebook: null,
    };
  },

  mounted() {
    this.$emit('step-changed');
    this.accessToken = this.facebookAccessToken;
  },

  methods: {
    handleLogin(response) {
      this.accessToken = response?.authResponse?.accessToken;
      this.$emit('facebook-auth-updated', this.accessToken);
    },

    handleSdkInit({ FB, scope }) {
      this.facebook = scope;
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          this.accessToken = response.authResponse.accessToken;
          this.$emit('facebook-auth-updated', this.accessToken);
        }
      });
    },

    next() {
      this.$emit('skip');
    },

    skip() {
      this.$vs.dialog({
        color: 'primary',
        title: 'Are you sure?',
        text: 'RevenueJump\'s functionality and reporting will be extremely limited without connecting to Facebook.',
        accept: () => this.$emit('skip'),
      });
    },
  },
};
</script>
