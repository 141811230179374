<template>
  <!-- Renewal  -->
  <div class="w-full flex sm:w-1/2 lg:w-1/4 px-4 mb-4">
    <vx-card class="rj-invoice-card">
      <div class="flex flex-start justify-between mb-4">
        <div
          v-if="upcomingInvoice"
          class="mr-4"
        >
          <h2>{{ formatCentsAsDollars(upcomingInvoice.total) }}</h2>
          <p>Monthly fee USD</p>
        </div>
        <div v-else>
          <p v-if="endedDate">
            Subscription ended on {{ endedDate }}
          </p>
        </div>
        <feather-icon
          icon="RefreshCcwIcon"
          class="p-3 inline-flex rounded-full"
          :style="{
            color: themeColors.rjBlue,
            'background-color': Color(themeColors.rjBlue)
              .lightness(93)
              .hex(),
          }"
        />
      </div>

      <div
        v-if="upcomingInvoice"
        class="mt-auto"
      >
        <p>Renews on {{ renewalDate }}</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { format } from 'date-fns';
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import { formatCentsAsDollars } from '@/utils';


export default {
  name: 'RjRenewalDate',
  props: {
    upcomingInvoice: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      themeColors,
      Color,
    };
  },
  computed: {
    renewalDate() {
      const date = this.$store.getters['partners/renewalDate'];
      if (!date) {
        return 'N/A';
      }
      return format(date, 'MMM. d, yyyy');
    },
    endedDate() {
      const subscription = this.$store.getters['partners/subscription'];
      if (subscription && subscription.ended_at) {
        console.log(subscription.ended_at);
        const date = new Date(subscription.ended_at * 1000);
        return format(date, 'MMM. d, yyyy');
      }
      return null;
    },
  },
  methods: {
    formatCentsAsDollars,
  },
};
</script>
