<template>
  <portal to="modals">
    <transition
      name="fullscreen-modal"
      mode="out-in"
      appear
    >
      <section class="fixed inset-0 flex items-center justify-center z-max">
        <div>
          <div
            class="absolute inset-0 bg-black opacity-50"
            @click="close"
          />
        </div>

        <div
          ref="rj-modal-content"
          class="rj-modal"
        >
          <slot />
        </div>
      </section>
    </transition>
  </portal>
</template>

<script>
export default {
  name: 'RjModal',

  created() {
    document.body.classList.add('overflow-hidden');
    document.addEventListener('keydown', this.onEscape);
  },

  beforeDestroy() {
    document.body.classList.remove('overflow-hidden');
    document.removeEventListener('keydown', this.onEscape);
  },

  methods: {
    onEscape(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
