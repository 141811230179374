<template>
  <div class="w-full flex sm:w-1/2 lg:w-1/4 px-4 mb-4">
    <vx-card class="rj-invoice-card">
      <div
        v-if="paymentMethod"
        class="flex items-center justify-between mb-4"
      >
        <div>
          <h2>{{ paymentMethod.brand }} {{ paymentMethod.last4 }}</h2>
          <p>Payment Method</p>
        </div>
        <feather-icon
          icon="CreditCardIcon"
          class="p-3 inline-flex rounded-full"
          :style="{
            color: themeColors.rjRed,
            'background-color': Color(themeColors.rjRed)
              .lightness(93)
              .hex(),
          }"
        />
      </div>
      <div
        v-else
        class="flex items-center justify-between mb-4"
      >
        <div class="pr-2">
          <p>Payment Method Missing</p>
        </div>
        <feather-icon
          icon="AlertTriangleIcon"
          class="p-3 inline-flex rounded-full"
          :style="{
            color: themeColors.rjRed,
            'background-color': Color(themeColors.rjRed)
              .lightness(93)
              .hex(),
          }"
        />
      </div>

      <div class="mt-auto">
        <vs-button
          color="primary"
          class="w-full mt-2"
          @click="togglePaymentModal"
        >
          Update
        </vs-button>
      </div>
    </vx-card>
    <rj-payment-method-form
      v-if="paymentModal"
      :payment-method="paymentMethod"
      @close="togglePaymentModal"
      @payment-method-updated="setPaymentMethod($event)"
    />
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import RjPaymentMethodForm from '@/views/partner-dashboard/subscription/modals/RjPaymentMethodForm.vue';

export default {
  name: 'RjPaymentMethod',
  components: {
    RjPaymentMethodForm,
  },
  props: {
    paymentMethod: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      themeColors,
      Color,
      paymentModal: false,
    };
  },
  computed: {
  },
  methods: {
    togglePaymentModal() {
      this.paymentModal = !this.paymentModal;
    },
    setPaymentMethod(paymentMethod) {
      this.$emit('payment-method-updated', paymentMethod);
    },
  },
};
</script>
