<template>
  <section class="w-full md:w-10/12 justify-center mx-auto">
    <div class="mb-6 text-center">
      <h1 class="text-3xl">
        Next Step for Success.
      </h1>
      <p class="text-lg mt-2 text-gray-600">
        Your client’s account is all set up! We now recommend sending {{ clientDetailsData.firstName }} a
        welcome email so they can log into their account. Feel free to create your own or send the email below right now.
      </p>
    </div>

    <div class="w-full border border-primary rounded-lg p-5">
      <p class="font-semibold">
        SUBJECT: Your New RevenueJump Account!!
      </p>
      <br>
      <p>Hello {{ clientDetailsData.firstName }},</p>
      <br>
      <p>
        I just finished setting up your new RevenueJump account! Please,
        feel free to login by navigating here:
        <a
          href="https://app.revenuejump.com"
          target="_blank"
        >
          https://app.revenuejump.com
        </a>
      </p>
      <br>
      <p>
        Then, click on the Reset Password link to setup your password. Your email address is also your username.
      </p>
      <br>
      <p>A few things of note:</p>
      <ol
        style="list-style-type: decimal; list-style-position: outside;"
        class="space-y-2 pt-3 pl-8"
      >
        <li>
          It takes a bit of time for the system to start capturing and reporting on your reviews
          (and those of your top competitors). So at first, don't be surprised to temporarily
          see a lot of zeros in the reports.
        </li>
        <li>
          I would suggest connecting your Facebook profile to RevenueJump so we can start monitoring
          those reviews as well! Simply navigate to Integrations > Applications > Facebook.
        </li>
        <li>
          Take a few minutes to review the frequently asked questions.
          It’s helpful and can be found when you navigate to the Help page.
        </li>
      </ol>
      <br>
      <p>
        If you have any questions along the way, RevenueJump is also happy to help!
        Just login and go to the Help menu to open a new support ticket.
      </p>
      <br>
      <p>Thank you and congrats!</p>
      <p>{{ user.firstName }} </p>
    </div>

    <div class="flex items-center justify-center mt-6 space-x-6">
      <vs-button
        v-if="!sent"
        size="large"
        type="border"
        @click="sendWelcomeEmail"
      >
        Send Email
      </vs-button>
      <vs-button
        size="large"
        type="border"
        @click="$emit('submit')"
      >
        I'm Done
      </vs-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RjFinishStep',

  props: {
    index: {
      type: Number,
      required: true,
    },
    clientDetailsData: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      sent: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters['auth/currentUser'];
    },
  },

  mounted() {
    this.$emit('step-changed');
    this.$emit('clear-local-storage');
  },

  methods: {
    async sendWelcomeEmail() {
      const partnerId = this.$store.getters['partners/partner'].id;
      const response = await this.$store.dispatch('partners/sendWelcomeEmail', {
        partnerId,
        companyId: this.companyId,
      });
      if (response.success) {
        this.$vs.notify({ title: 'Success', text: 'Email sent!', color: 'success' });
        this.sent = true;
      } else {
        this.$vs.notify({ title: 'Error', text: response.errorMessage, color: 'danger' });
      }
    },
  },
};
</script>
