export default {
  data() {
    return {
      fw_index: 0,
    };
  },

  computed: {
    fw_step() {
      return this.steps[this.fw_index];
    },
  },

  methods: {
    /**
     * Skips the current step.
     *
     * @return {void}
     */
    $_fw_skip() {
      this.$_fw_next();
    },

    /**
     * Goes to the next step in the form.
     *
     * @return {void}
     */
    $_fw_next() {
      // this.$refs.modal.$refs['rj-modal-content'].scrollTop = 0;
      this.fw_index += (this.fw_index >= this.steps.length - 1) ? 0 : 1;
    },

    /**
     * Navigates one step back on the form.
     *
     * @return {void}
     */
    $_fw_previous() {
      // this.$refs.modal.$refs['rj-modal-content'].scrollTop = 0;
      this.fw_index -= (this.fw_index <= 0) ? 0 : 1;
    },
  },
};
